import { constants } from "@helpers/constants";
import { createSlice } from "@reduxjs/toolkit";
import { partesService } from "@services/partes";
import { reportLinesService } from "@services/reportLines";
import { useSelector } from "react-redux";

const initial: any = {
  // Listados.
  list: [],
  listValidating: [],
  // Contadores.
  totalItems: 0,
  totalItemsAll: 0,
  totalItemsAbiertos: 0,
  totalItemsConfirmados: 0,
  totalItemsValidados: 0,
  totalItemsRechazados: 0,
  // Filtros.
  selectedDateTo: null,
  selectedDateFrom: null,
  selectedTypeHour: 1,
  selectedEmpresa: null,//getInternalCompany(),
  selectedEmpleado: null,
  selectedEmpleadoHome: null,
  selectedResponsable: null,
  selectedObra: null,
  filterByType: "",
  search: null,
  // Otras. Revisar estas.
  closedDays: [],
  lines: [],
  resumeTotalItems: 0,
  resumeTotalHoras: 0,
  resumeTotalImporte: 0
};

export const parteSlice = createSlice({
  name: "partes",
  initialState: { ...initial },
  reducers: {
    resetPartes: (state, action: any) => {
      state.list = [...initial.list];
      state.selectedDateTo = initial.selecteddateto;
      state.selectedDateFrom = initial.selecteddatefrom;
      state.selectedTypeHour = initial.selectedtypehour;
      state.selectedEmpresa = initial.selectedempresa;
      state.filterByType = initial.filterByType;
      state.search = initial.search;

      state.selectedEmpleado = initial.selectedEmpleado;
      state.selectedEmpleadoHome = initial.selectedEmpleadoHome;
      state.selectedResponsable = initial.selectedResponsable;
    },
    setPartes: (state, action: any) => {
      const { list = [], totalItems = 0 } = action.payload
      state.list = [...list] as any;
      state.totalItems = totalItems as any;
    },
    setPartesAllTotalItems: (state, action: any) => {
      const { totalItemsAll = 0, totalItemsAbiertos = 0, totalItemsConfirmados = 0, totalItemsValidados = 0, totalItemsRechazados = 0 } = action.payload
      state.totalItemsAll = totalItemsAll as any;
      state.totalItemsAbiertos = totalItemsAbiertos as any;
      state.totalItemsConfirmados = totalItemsConfirmados as any;
      state.totalItemsValidados = totalItemsValidados as any;
      state.totalItemsRechazados = totalItemsRechazados as any;
    },
    setPartesResume: (state, action: any) => {
      const { resumeTotalItems = 0, resumeTotalHoras = 0, resumeTotalImporte = 0 } = action.payload
      state.resumeTotalItems = resumeTotalItems as any;
      state.resumeTotalHoras = resumeTotalHoras as any;
      state.resumeTotalImporte = resumeTotalImporte as any;
    },
    setPartesValidating: (state, action: any) => {
      state.listValidating = [...action.payload] as any;
    },
    updateParte: (state, action: any) => {
      state.list = [...state.list].map((parte: any) => {
        if (parte.id == action.payload.id) {
          return action.payload.parte;
        }

        return parte;
      }) as any;
    },
    setSelectedDateFrom: (state, action: any) => {
      (state.selectedDateFrom as any) = new Date(action.payload).getTime()
    },
    setSelectedDateTo: (state, action: any) => {
      (state.selectedDateTo as any) = new Date(action.payload).getTime()
    },
    setSelectedEmpresa: (state, action: any) => {
      state.selectedEmpresa = action.payload
    },
    setSelectedObra: (state, action: any) => {
      state.selectedObra = action.payload
    },
    setSelectedTypeHour: (state, action: any) => {
      state.selectedTypeHour = action.payload
    },
    setFilterByType: (state, action: any) => {
      state.filterByType = action.payload
    },
    setClosedDay: (state, action: any) => {
      state.closedDays = [...action.payload] as any;
    },
    setLines: (state, action: any) => {
      state.lines = [...action.payload] as any;
    },
    setSelectedEmpleado: (state, action: any) => {
      state.selectedEmpleado = action.payload
    },
    setSelectedEmpleadoHome: (state, action: any) => {
      state.selectedEmpleadoHome = action.payload
    },
    setSelectedResponsable: (state, action: any) => {
      state.selectedResponsable = action.payload
    },
  },
});

export default parteSlice.reducer;
export const {
  setPartes,
  setPartesAllTotalItems,
  setPartesResume,
  setPartesValidating,
  resetPartes,
  updateParte,
  setSelectedDateFrom,
  setSelectedDateTo,
  setSelectedTypeHour,
  setSelectedEmpresa,
  setSelectedObra,
  setFilterByType,
  setClosedDay,
  setLines,
  setSelectedEmpleado,
  setSelectedEmpleadoHome,
  setSelectedResponsable
} = parteSlice.actions;

export const getPartes = (months: number = 1, page: number = 0, pageSize: number = 50, estado: number, filters: any) => (dispatch: any) => {
  partesService
    .get(months, page, pageSize, estado, filters)
    .then((res: any) => dispatch(setPartes({
      list: res.data?.data?.items ?? [],
      totalItems: res.data?.data?.total_items ?? 0,
    } as any)));

  partesService
    .getAllTotalItems(months, filters)
    .then((res: any) => dispatch(setPartesAllTotalItems({
      totalItemsAll: res.data?.data?.total_items_all ?? 0,
      totalItemsAbiertos: res.data?.data?.total_items_abiertos ?? 0,
      totalItemsConfirmados: res.data?.data?.total_items_confirmados ?? 0,
      totalItemsValidados: res.data?.data?.total_items_validados ?? 0,
      totalItemsRechazados: res.data?.data?.total_items_rechazados ?? 0,
    } as any)));

  partesService
    .getClosedDay()
    .then((res: any) => dispatch(setClosedDay((res.data as any)?.data as any)));

  partesService
    .getPartesValidating(months)
    .then((res: any) => dispatch(setPartesValidating((res.data as any)?.data as any)));

  reportLinesService
    .get(months)
    .then((res: any) => dispatch(setLines((res.data as any)?.data as any)));

  partesService
    .getResume(months, estado, filters)
    .then((res: any) => dispatch(setPartesResume({
      resumeTotalItems: res.data?.data?.total_items ?? 0,
      resumeTotalHoras: res.data?.data?.total_hours ?? 0,
      resumeTotalImporte: res.data?.data?.total_importe ?? 0,
    } as any)));
};
