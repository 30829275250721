import moment from "moment";

/**
 * This function converts a number into price format string.
 * @param value Number to convert into price format.
 * @returns String with the price format
 */
export const priceFormat = (value: number): string => {
  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(value)
}

export const dateFormat = (date: any) => {
  return moment(date).format("DD-MM-YYYY kk:mm:ss")
}

export const dateDifferenceHours = (_dateOne: any, _dateTwo: any) => {
  const dateOne = moment(_dateOne)
  const dateTwo = moment(_dateTwo)

  return dateOne.diff(dateTwo, "hours", true)
}

export const getCurrentMonth = () => {
  const now = moment()
  return ({
    monthName: now.format('MMMM'),
    yearName: now.format('YYYY'),
    monthIndex: now.month()
  })
}

export const getPreviousMonths = (numberOfMonths = 1) => {
  const previousMonths = []

  for (let i = 0; i < numberOfMonths; i++) {
    const currentMonth = moment().subtract(i, 'months')
    previousMonths.push({
      monthName: currentMonth.format('MMMM'),
      yearName: currentMonth.format('YYYY'),
      monthIndex: currentMonth.month()
    })
  }

  return previousMonths
}

export const getDaysOfMonth = (month = "January", year = "2024") => {
  const monthYearString = `${month}-${year}`
  const date = moment(monthYearString, 'MMMM-YYYY')
  const days = []

  const monthDays = date.daysInMonth()

  for (let i = 1; i <= monthDays; i++) {
    days.push(i)
  }

  return days
}

export const roundUpNumber = (number: number, precision: number) => {
  const multiplier = Math.pow(10, precision)
  return Math.ceil(number * multiplier) / multiplier
}

export const getBase64FromCanvaDiv = (idCanvaDiv: string) => {
  const canvaRef = document.getElementById(idCanvaDiv);
  const canvas = canvaRef?.querySelector("canvas");
  const dataURL = canvas?.toDataURL();

  return dataURL
}

export const convertToBase64FromFileInput = (file: File, withoutBase64Header = false): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      let base64String = ""

      if (withoutBase64Header) {
        base64String = (e.target?.result as string).split(',')[1];
      } else {
        base64String = (e.target?.result as string)
      }

      resolve(base64String)
    }

    reader.onerror = () => {
      reject(null)
    }

    reader.readAsDataURL(file)
  })
}

export const delay = (temp: number) => {
  let t = temp / 1;

  return new Promise(resolve => setTimeout(resolve, t));
}

export const filterUniqueByKey = (array: any[], key: string) => {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
};