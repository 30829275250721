import { createSlice } from "@reduxjs/toolkit";

const initial = {
  list: [],
};

export const productsBarcodesSlice = createSlice({
  name: "productsBarcodes",
  initialState: { ...initial },
  reducers: {
    resetProductsBarcodes: (state, action: any) => {
      state.list = [...initial.list];
    },
    setProductsBarcodes: (state, action: any) => {
      state.list = [...(action.payload ?? [])] as any;
    },
  },
});

export default productsBarcodesSlice.reducer;
export const { setProductsBarcodes, resetProductsBarcodes } = productsBarcodesSlice.actions;

