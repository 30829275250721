const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map((v) => (v === "null" ? 0 : Number(v)))
  return hours * 60 + minutes
}

export const generateHourOptions = (initialHour_?: string, isQuantity?: boolean) => {
  const hourOptions = Array.from(Array(25).keys())

  if (!initialHour_ || !isQuantity) return hourOptions
  const initialHour = initialHour_.replace("null", "00")

  const initialTimeInMinutes = timeToMinutes(initialHour)
  const maxTime = 24 * 60

  return hourOptions.filter((hour) => {
    const hourInMinutes = hour * 60
    return (hourInMinutes + initialTimeInMinutes) <= maxTime
  })
}

export const generateMinuteOptions = (initialHour_?: string, isQuantity?: boolean, selectedHour?: string | null) => {
  const minuteOptions = Array.from(Array(4).keys())

  if (!initialHour_ || !isQuantity) return minuteOptions
  const initialHour = initialHour_.replace("null", "00")
  const minuteMapping = [0, 15, 30, 45]

  const initialTimeInMinutes = timeToMinutes(initialHour)
  const selectedHourInMinutes = !!selectedHour ? Number(selectedHour) * 60 : 0
  const maxTime = 24 * 60

  const minuteBase = selectedHourInMinutes + initialTimeInMinutes

  return minuteOptions.filter((minuteIndex) => {
    const minuteValue = minuteMapping[minuteIndex]
    const totalMinutes = minuteBase + minuteValue
    return totalMinutes >= initialTimeInMinutes && totalMinutes <= maxTime
  })
}