import { Articulo } from "@components/FormParte/BBs/LineasArticulo/LineasArticulo"
import { getRequest } from "@screens/Backoffice/utils/requests"

export const checkResponsableObraIsIntern = async (obra: any) => {
  try {
    const filter = { empresa: obra?.company, empleado: obra?.responsible }
    const response = await getRequest(`users/find?filter=${JSON.stringify(filter)}`)

    const userExtraData = JSON.parse(response?.data?.data ?? "{}")

    const isIntern = userExtraData.internal?.some((par: { empleado: any; empresa: any }) => par.empresa === obra?.company && par.empleado === obra?.responsible);
    return isIntern
  } catch (error) {
    return false
  }
}

export const matchArticlesWithBarcodes = (articles: any[] = [], barcodes: any[] = []): any[] => {
  if (!articles.length) return []
  if (!barcodes.length) return articles

  const articleProductsIds = new Set(articles.map(article => article.Articulo))
  const filteredBarcodes = barcodes.filter(barcode => articleProductsIds.has(barcode.product_id))

  const barcodesByProductId = filteredBarcodes.reduce((acc, barcode) => {
    const { product_id, barcode: barcodeValue } = barcode
    if (!acc[product_id]) {
      acc[product_id] = []
    }
    acc[product_id].push(barcodeValue)
    return acc
  }, {})

  return articles.map(article => {
    const { Articulo } = article
    return {
      ...article,
      barcodes: barcodesByProductId[Articulo] || [],
    }
  })
}

export const searchArticuloByWord = (articulo: Articulo, word: string): boolean => {
  if (!word || word === "") return true

  const searchWord = word.toLowerCase()

  const fieldsToSearch = ["Descripcion", "Articulo", "Referencia", "Auxiliar", "Marca"]
  const foundInFields = fieldsToSearch.some(field => {
    const fieldValue = articulo[field as keyof Articulo]?.toString()?.toLowerCase()
    return fieldValue?.includes(searchWord)
  })

  const foundInBarcodes = articulo?.barcodes?.some(barcode => barcode?.toString()?.toLowerCase().includes(searchWord))

  return Boolean(foundInFields || foundInBarcodes)
}