import { useEffect, useState } from "react";
import Header from "@components/Header/Header";
import EsfirusResume from "@components/ui/Resume/EsfirusResume";
import PartesSubHeader from "./BBs/subheader/subheader";
import EsfirusPartesTable from "./BBs/table/Table";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import backIcon from "@assets/images/icons/back-arrow.svg";
import pendingIcon from "@assets/svgs/pending-icon.svg";
import { useNavigate } from "react-router-dom";
import "./Pending.scss";
import { useDispatch, useSelector } from "react-redux";
import { filterPartes } from "@helpers/parsers";
import { deleteReport } from "@store/slices/reports";
import { emptyResourceRedirect } from "@helpers/navigate";
import usePartesGet from "@services/hooks/usePartesGet";

function Pending() {
  const partes = useSelector((state) => (state as any).partes).listValidating;
  const dispatch = useDispatch();
  const getPartes = usePartesGet()


  const [parteslist, setListPartes] = useState([]);
  const [pendingSelected, setPendingSelected] = useState({});
  const [seletedAll, setSeletedAll] = useState(false);
  const [search, setFilterBySearch] = useState<any>("");

  const selectedDateFrom = useSelector((state) => (state as any).partes.selectedDateFrom);
  const selectedEmpresa = useSelector((state) => (state as any).partes.selectedEmpresa);
  const selectedObra = useSelector((state) => (state as any).partes.selectedObra);
  const selectedDateTo = useSelector((state) => (state as any).partes.selectedDateTo);
  const configuration = useSelector((state) => (state as any).configurations.config);
  const selectedEmpleado = useSelector((state) => (state as any).partes.selectedEmpleado);
  const selectedResponsable = useSelector((state) => (state as any).partes.selectedResponsable);
  const filterByType = useSelector((state) => (state as any).partes).filterByType;

  const empresas = useSelector((state) => (state as any).empresas).list;

  const navigate = useNavigate();
  const rol = localStorage.getItem("ef-user-rol")

  const [empleadosList, setEmpleadosList] = useState<any>([]);
  const [responsablesList, setResponsablesList] = useState<any>([]);


  useEffect(() => {
    // const user = getTokenDecoded()
    // setFilterBySearch(user.nombre + " " + user.apellidos)

    emptyResourceRedirect({ check: empresas.length === 0, path: "/partes/pendientes", navigate });
    dispatch(getPartes(0, 50, filterByType) as any)
  }, [])

  useEffect(() => {
    const listaEmpleados = partes?.map?.((p: any) => {
      const empresa = empresas?.find((e: any) => e.Empresa == (p.empresaExterna ? p.empresaExterna : p.empresa))
      return `${p.nombre_empleado} - ${empresa?.Nombre}#${p.usuario}`
    })?.
      filter((value: any, index: any, array: any) => array.indexOf(value) === index)?.
      sort((a: any, b: any) => a.localeCompare(b))?.
      map((_e: any) => {
        const empleado = _e.split("#")
        return { label: empleado[0], value: empleado[1] }
      });
    setEmpleadosList(listaEmpleados)

    const listaResponsables = partes?.map?.((p: any) => p.nombre_responsable)?.
      filter((value: any, index: any, array: any) => array.indexOf(value) === index)?.
      map((_e: any) => ({ label: _e, value: _e }));
    setResponsablesList(listaResponsables)
  }, [partes])


  useEffect(() => {
    // Filtros de cabecera.
    const listPartesUpdated = filterPartes(
      partesOrdered(),
      partes,
      selectedDateFrom,
      selectedDateTo,
      selectedEmpresa,
      selectedObra,
      search,
      selectedEmpleado,
      selectedResponsable,
      { ...configuration, listado: {} }, //quitamos los filtros del listado        
    )

    setListPartes(listPartesUpdated)
    setPendingSelected({})
  }, [partes, selectedDateFrom, selectedDateTo, search, selectedEmpresa, selectedObra, selectedEmpleado, selectedResponsable]);

  const deleteParte = (id: any) => {
    dispatch(deleteReport(id) as any)
      .then(
        () => dispatch(getPartes(0, 50, filterByType) as any)
      );
  };

  const partesOrdered = () => {
    return partes?.slice().sort((a: any, b: any) => b.id - a.id);
  };

  return (
    <>
      <Header />
      <div className="center">
        <div className="header-pending">
          <EsfirusButtonNative
            className={"tertiary"}
            label={window.innerWidth > 500 ? "Volver" : ""}
            icon={backIcon}
            iconClass={"icon-tertiary"}
            click={() => {
              navigate("/partes")
            }}
          ></EsfirusButtonNative>

          <div className="title">
            <div className="pending-title-container">
              <img src={pendingIcon} alt="pendingIcon" srcSet="" />
              <h4 className="pending-title">Pendiente de validar</h4>
            </div>
            <h5>Selecciona el parte que quieres validar</h5>
          </div>
        </div>
        <PartesSubHeader
          search={search}
          setFilterBySearch={setFilterBySearch}
          partes={parteslist}
          pendingSelected={pendingSelected}
          selectedEmpresa={selectedEmpresa}
          selectedDate={selectedDateFrom}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          selectedEmpleado={selectedEmpleado}
          empleadosList={empleadosList}
          selectedResponsable={selectedResponsable}
          responsablesList={responsablesList}
        ></PartesSubHeader>
        <EsfirusPartesTable
          seletedAll={seletedAll}
          pendingSelected={pendingSelected}
          partes={parteslist as any}
          deleteReport={deleteParte}
          empresas={empresas as any}
          rol={rol as any}
          onCheckRow={(row: { row: string, value: boolean }) => {
            if (!row.value) {
              setSeletedAll(false);
            }

            const newSelection = { ...pendingSelected };
            (newSelection as any)[row.row] = row.value
            setPendingSelected(newSelection as never)
          }}
          onCheckAll={() => {
            setSeletedAll(!seletedAll);
            const newSelection = {};
            parteslist.forEach((parte: any) => {
              (newSelection as any)[parte.id] = !seletedAll;
            });
            setPendingSelected(newSelection as never)
          }}
          onOpenEdit={(opened: any) => {
            setSeletedAll(false);
            const newSelection = {};
            (newSelection as any)[opened.id] = true;
            setPendingSelected(newSelection as never)
          }}
        ></EsfirusPartesTable>
        <EsfirusResume partes={parteslist}></EsfirusResume>
      </div >
    </>
  );
}

export default Pending;
